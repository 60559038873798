<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 8C20.6569 8 22 6.65685 22 5C22 3.34315 20.6569 2 19 2C17.3431 2 16 3.34315 16 5C16 6.65685 17.3431 8 19 8Z"
      fill="#DAEDFF"
    />
    <path
      d="M19.8 9.42C19.77 9.43 19.74 9.43 19.71 9.44C19.63 9.45 19.56 9.46 19.47 9.47C19.32 9.49 19.16 9.5 19 9.5C18.57 9.5 18.16 9.44 17.77 9.32C17.6 9.28 17.44 9.23 17.29 9.16C17.14 9.1 16.98 9.02 16.84 8.94C16.09 8.53 15.47 7.91 15.06 7.16C14.98 7.02 14.9 6.86 14.84 6.71C14.77 6.56 14.72 6.4 14.68 6.23C14.56 5.84 14.5 5.43 14.5 5C14.5 4.84 14.51 4.68 14.53 4.53C14.54 4.44 14.55 4.37 14.56 4.29C14.57 4.26 14.57 4.23 14.58 4.2C14.7 3.58 14.24 3 13.6 3H7.52C7.38 3 7.24 3.01 7.11 3.02C6.99 3.03 6.88 3.04 6.76 3.06C6.64 3.07 6.52 3.09 6.41 3.11C4 3.46 2.46 4.99 2.11 7.41C2.09 7.52 2.07 7.64 2.06 7.76C2.04 7.88 2.03 7.99 2.02 8.11C2.01 8.24 2 8.38 2 8.52V16.48C2 16.62 2.01 16.76 2.02 16.89C2.03 17.01 2.04 17.12 2.06 17.24C2.07 17.36 2.09 17.48 2.11 17.59C2.46 20.01 4 21.54 6.41 21.89C6.52 21.91 6.64 21.93 6.76 21.94C6.88 21.96 6.99 21.97 7.11 21.98C7.24 21.99 7.38 22 7.52 22H15.48C15.62 22 15.76 21.99 15.89 21.98C16.01 21.97 16.12 21.96 16.24 21.94C16.36 21.93 16.48 21.91 16.59 21.89C19 21.54 20.54 20.01 20.89 17.59C20.91 17.48 20.93 17.36 20.94 17.24C20.96 17.12 20.97 17.01 20.98 16.89C20.99 16.76 21 16.62 21 16.48V10.4C21 9.76 20.42 9.3 19.8 9.42ZM12.28 15.25C11.75 16.92 9.93 17.82 9.3 17.82C8.66 17.82 6.87 16.94 6.33 15.25H6.32C5.97 14.14 6.37 12.69 7.64 12.28C8.22 12.09 8.84 12.2 9.29 12.55C9.74 12.2 10.37 12.09 10.96 12.28C12.23 12.69 12.63 14.14 12.28 15.25Z"
      fill="#DAEDFF"
    />
  </svg>
</template>
<script>
export default {
  name: "BillingIcon",
};
</script>
