<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8931 9.65967L12.2265 8.55301C12.0865 8.30634 11.9598 7.83967 11.9598 7.56634V5.87967C11.9598 4.31301 11.0398 2.95967 9.71313 2.32634C9.36647 1.71301 8.72647 1.33301 7.99313 1.33301C7.26647 1.33301 6.61313 1.72634 6.26647 2.34634C4.96647 2.99301 4.06647 4.33301 4.06647 5.87967V7.56634C4.06647 7.83967 3.9398 8.30634 3.7998 8.54634L3.12647 9.65967C2.8598 10.1063 2.7998 10.5997 2.96647 11.053C3.12647 11.4997 3.50647 11.8463 3.9998 12.013C5.29313 12.453 6.65313 12.6663 8.01313 12.6663C9.37313 12.6663 10.7331 12.453 12.0265 12.0197C12.4931 11.8663 12.8531 11.513 13.0265 11.053C13.1998 10.593 13.1531 10.0863 12.8931 9.65967Z"
      fill="white"
    />
    <path
      d="M9.88678 13.3397C9.60678 14.113 8.86678 14.6663 8.00012 14.6663C7.47345 14.6663 6.95345 14.453 6.58678 14.073C6.37345 13.873 6.21345 13.6063 6.12012 13.333C6.20678 13.3463 6.29345 13.353 6.38678 13.3663C6.54012 13.3863 6.70012 13.4063 6.86012 13.4197C7.24012 13.453 7.62678 13.473 8.01345 13.473C8.39345 13.473 8.77345 13.453 9.14678 13.4197C9.28678 13.4063 9.42678 13.3997 9.56012 13.3797C9.66678 13.3663 9.77345 13.353 9.88678 13.3397Z"
      fill="white"
    />
  </svg>
</template>
<script>
export default {
  name: "NotificationIcon",
};
</script>
