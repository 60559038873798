<template>
  <main class="d-flex">
    <TheSidebar />
    <div class="overflow-auto flex-grow-1 vh-100">
      <TheHeader />
      <router-view />
    </div>
  </main>
</template>

<script>
import TheSidebar from "../components/TheSidebar.vue";
import TheHeader from "../components/TheHeader.vue";

export default {
  components: {
    TheSidebar,
    TheHeader,
  },
};
</script>
<style lang="scss">
body {
  background-color: $main-body-bg;
}
</style>
