<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 10C20 4.49 15.51 0 10 0C4.49 0 0 4.49 0 10C0 12.9 1.25 15.51 3.23 17.34C3.23 17.35 3.23 17.35 3.22 17.36C3.32 17.46 3.44 17.54 3.54 17.63C3.6 17.68 3.65 17.73 3.71 17.77C3.89 17.92 4.09 18.06 4.28 18.2C4.35 18.25 4.41 18.29 4.48 18.34C4.67 18.47 4.87 18.59 5.08 18.7C5.15 18.74 5.23 18.79 5.3 18.83C5.5 18.94 5.71 19.04 5.93 19.13C6.01 19.17 6.09 19.21 6.17 19.24C6.39 19.33 6.61 19.41 6.83 19.48C6.91 19.51 6.99 19.54 7.07 19.56C7.31 19.63 7.55 19.69 7.79 19.75C7.86 19.77 7.93 19.79 8.01 19.8C8.29 19.86 8.57 19.9 8.86 19.93C8.9 19.93 8.94 19.94 8.98 19.95C9.32 19.98 9.66 20 10 20C10.34 20 10.68 19.98 11.01 19.95C11.05 19.95 11.09 19.94 11.13 19.93C11.42 19.9 11.7 19.86 11.98 19.8C12.05 19.79 12.12 19.76 12.2 19.75C12.44 19.69 12.69 19.64 12.92 19.56C13 19.53 13.08 19.5 13.16 19.48C13.38 19.4 13.61 19.33 13.82 19.24C13.9 19.21 13.98 19.17 14.06 19.13C14.27 19.04 14.48 18.94 14.69 18.83C14.77 18.79 14.84 18.74 14.91 18.7C15.11 18.58 15.31 18.47 15.51 18.34C15.58 18.3 15.64 18.25 15.71 18.2C15.91 18.06 16.1 17.92 16.28 17.77C16.34 17.72 16.39 17.67 16.45 17.63C16.56 17.54 16.67 17.45 16.77 17.36C16.77 17.35 16.77 17.35 16.76 17.34C18.75 15.51 20 12.9 20 10ZM14.94 14.97C12.23 13.15 7.79 13.15 5.06 14.97C4.62 15.26 4.26 15.6 3.96 15.97C2.44 14.43 1.5 12.32 1.5 10C1.5 5.31 5.31 1.5 10 1.5C14.69 1.5 18.5 5.31 18.5 10C18.5 12.32 17.56 14.43 16.04 15.97C15.75 15.6 15.38 15.26 14.94 14.97Z"
      fill="#DAEDFF"
    />
  </svg>
</template>
<script>
export default {
  name: "TimesheetIcon",
};
</script>
