<template>
  <header class="main-header d-flex justify-content-between">
    <div class="main-header__left d-flex flex-column justify-content-center">
      <h1>{{ title }}</h1>
      <p>{{ description }}</p>
    </div>
    <div class="main-header__right d-flex align-items-center">
      <b-badge
        class="d-flex align-items-center justify-content-center mr-3 m-cursor-pointer"
        variant="dark-blue"
      >
        <NotificationIcon />
      </b-badge>
      <b-badge
        class="d-flex align-items-center justify-content-center mr-2 m-cursor-pointer"
        variant="light-blue"
      >
        {{ user.initials }}
      </b-badge>
      <span class="d-none d-md-block">
        <span class="main-header__user-name d-block">{{ user.name }}</span>
        <span class="main-header__email d-block">{{ user.email }}</span>
      </span>
    </div>
  </header>
</template>
<script>
import { mapActions, mapState } from "vuex";
import NotificationIcon from "../components/svg/NotificationIcon.vue";
export default {
  components: {
    NotificationIcon,
  },
  data() {
    return {
      customRoutes: ["assessment-details", "skill-test"],
    };
  },
  computed: {
    ...mapState(["user", "pageTitle", "pageDescription"]),
    title() {
      return this.customRoutes.includes(this.$route.name)
        ? (this.pageTitle ?? this.$route.meta.title ?? "")
        : this.$route.meta.title;
    },
    description() {
      return this.customRoutes.includes(this.$route.name)
        ? (this.pageDescription ?? this.$route.meta.description ?? "")
        : this.$route.meta.title;
    },
  },
  created() {
    this.fetchUser();
  },
  methods: {
    ...mapActions({
      fetchUser: "auth/fetchUser",
    }),
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  background-color: $body-bg;
  color: $dark-blue;
  height: 131px;
  padding: 2.65% 3.8% 0.2% 3.8%;
  h1 {
    font-size: 1.875rem;
    font-weight: 600;
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }
  }
  p {
    @include media-breakpoint-down(sm) {
      font-size: 0.875rem;
    }
  }
  &__right {
    .badge {
      padding: 0.6em 0.6em;
      height: 32px;
      width: 32px;
    }
    .badge-light-blue {
      color: $tertiary-color;
    }
  }
  &__user-name {
    font-weight: 600;
  }
  &__email {
    color: $faint-blue;
    font-size: 0.875rem;
  }
}
</style>
