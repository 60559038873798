<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6775 19.957C12.9523 20.0209 12.9775 20.3807 12.7099 20.4699L11.1299 20.9899C7.15985 22.2699 5.06985 21.1999 3.77985 17.2299L2.49985 13.2799C1.21985 9.30992 2.27985 7.20992 6.24985 5.92992L6.77385 5.75639C7.17671 5.62297 7.56878 6.02703 7.45438 6.43571C7.39768 6.63828 7.34314 6.84968 7.28985 7.06992L6.30985 11.2599C5.20985 15.9699 6.81985 18.5699 11.5299 19.6899L12.6775 19.957Z"
      fill="#DAEDFF"
    />
    <path
      d="M17.1702 3.20952L15.5002 2.81952C12.1602 2.02952 10.1702 2.67952 9.00018 5.09952C8.70018 5.70952 8.46018 6.44952 8.26018 7.29952L7.28018 11.4895C6.30018 15.6695 7.59018 17.7295 11.7602 18.7195L13.4402 19.1195C14.0202 19.2595 14.5602 19.3495 15.0602 19.3895C18.1802 19.6895 19.8402 18.2295 20.6802 14.6195L21.6602 10.4395C22.6402 6.25952 21.3602 4.18952 17.1702 3.20952ZM15.2902 13.3295C15.2002 13.6695 14.9002 13.8895 14.5602 13.8895C14.5002 13.8895 14.4402 13.8795 14.3702 13.8695L11.4602 13.1295C11.0602 13.0295 10.8202 12.6195 10.9202 12.2195C11.0202 11.8195 11.4302 11.5795 11.8302 11.6795L14.7402 12.4195C15.1502 12.5195 15.3902 12.9295 15.2902 13.3295ZM18.2202 9.94952C18.1302 10.2895 17.8302 10.5095 17.4902 10.5095C17.4302 10.5095 17.3702 10.4995 17.3002 10.4895L12.4502 9.25952C12.0502 9.15952 11.8102 8.74952 11.9102 8.34952C12.0102 7.94952 12.4202 7.70952 12.8202 7.80952L17.6702 9.03952C18.0802 9.12952 18.3202 9.53952 18.2202 9.94952Z"
      fill="#DAEDFF"
    />
  </svg>
</template>
<script>
export default {
  name: "NotesIcon",
};
</script>
